$(document).ready(function () {
	
	$.fn.isOnScreen = function(shift) {
		if (!shift) {
			shift = 0;
		}
		var viewport = {};
		viewport.top = $(window).scrollTop();
		viewport.bottom = viewport.top + $(window).height();
		var bounds = {};
		bounds.top = this.offset().top + shift;
		bounds.bottom = bounds.top + this.outerHeight() - shift;
		return ((bounds.top <= viewport.bottom) && (bounds.bottom >= viewport.top));
	}


	$('.nav-iconMenu').on('click', function() {
		$('.nav-menu').toggleClass('nav-menu_visible');
	});

	$('.nav-menu a').on('touchstart click', function(e) {
		e.preventDefault();

		if($(window).width() <  992) {
			$('html, body').animate( {
				scrollTop: $($(this).attr('href')).offset().top}, 100 );
		} else {
			$('html, body').animate( {
				scrollTop: $($(this).attr('href')).offset().top}, 100 );
		}
		return false;
	})

	$('.gellary-item').fancybox();

	var clock = 1680;
	$('.timer').FlipClock(clock, { countdown: true, language:'es-es'	});

	var toForm = function() {
		$('.to_form').click(function(e) {
			e.preventDefault();

			var a = $('.js_submit'), b = a.closest('form');
			if($('form#toform').length) a = $('#toform .js_submit'), b = a.closest('form#toform');
			if(b.length && a.is(':visible')) {
				$('html, body').animate({scrollTop: b.offset().top}, 1000);
			}
			return false; 
		})
	}

	var _bxInit = function(elem, opt) {
		var breakPoint = 992;
		var init = {
			sliderActive : false,
		}
		var flag = false;
		var slider;
		var slideClone = $(elem).clone();
		var options = opt;

		function createSlider() {
			slider = $(elem).bxSlider(options);
			return true;
		}

		if (window.innerWidth < breakPoint) {
			createSlider();
			init.sliderActive = true;
		}

		$(window).resize(function() {
			if (window.innerWidth >= breakPoint) {
				if(init.sliderActive) {
					slider.destroySlider();
					init.sliderActive = false;
					slider.replaceWith(slideClone.clone());
				}
			}
			if (window.innerWidth < breakPoint) {
				if(!init.sliderActive) {
					createSlider();
					init.sliderActive = true;
				}
			}
		});

		var a,b;
		a =1;
		b = 0;

		$(window).on('scroll', function() {
			if(init.sliderActive == true) {
				if (slider.isOnScreen()) {
					b =1;
				}else {
					b = 0;
				}

				if (a == b) {
					slider.startAuto();
				}else {
					slider.stopAuto();
				}
			}
		});
	} 

	_bxInit('.catalog', {
		adaptiveHeight: false,
		swipeThreshold: 40,
		controls: false,
		auto: true,
		pause: 5000,
		autoHover: true,
		slideSelector: '.catalog-item',
		slideMargin: 5,
	})
	toForm();

});

